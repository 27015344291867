.dapp-topbar {
  width: 100%;
  align-items: center;
  justify-content: space-around !important;
  .socail-links {
    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 6px !important;
    min-width: fit-content !important;
    background-color: #283760 !important;
    padding: 12px !important;
  }
  .socail-links:hover{
    background-color: #3b5394 !important;
  }
  .logo-section{
    display: flex;
    align-items: center;
    .logo-text{
      margin-left: 10px;
      font-family: "VarinoNormal";
    }
    .logo-text2{
      margin-left: 30px;
      font-family: "Poppins";
      font-size: 18px;
      margin-top: 5px;
    }
  }
}
.tablet .dapp-topbar {
  justify-content: space-between !important;
}
.pending-txn-container {
  display: flex;
  align-items: center;
  .MuiButton-label {
    margin-right: 0;
    transition: all 0.33s ease-out;
  }
}
#ohm-popper-pending {
  .MuiPaper-root.ohm-menu {
    a {
      padding-right: 20px !important;
    }
  }
}
.caret-down {
  position: absolute;
  right: 0.4rem;
}
.hovered-button {
  .MuiButton-label {
    margin-right: 0.5rem;
    transition: all 0.33s ease-out;
  }
}
